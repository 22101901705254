import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';

import { BaseImage } from 'src/app/models/Image';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

@Component({
  selector: 'app-dialog-show-image',
  templateUrl: './dialog-show-image.component.html',
  styleUrls: ['./dialog-show-image.component.css'],
})
// extends ClrModal
export class DialogShowImageComponent implements OnInit, AfterViewInit {
  @Input() images: BaseImage[] = [];
  @Input() slideIndex = 0;
  @Output() modalOpen = new EventEmitter<boolean>();
  imgLength = this.images.length;
  imgViewSrc = '';
  currentTxt = '';
  service = inject(AssessmentStoreService);

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.showSlides(this.slideIndex);
  }

  plusSlides(n: number) {
    this.slideIndex += n;
    this.showSlides(this.slideIndex);
  }

  currentSlide(n: number) {
    this.slideIndex = n;
    this.showSlides(this.slideIndex);
  }

  setDialogImgSrc() {
    if (this.images && this.images[this.slideIndex]?._id) {
      if (this.images[this.slideIndex]?._id) {
        this.service
          .loadImage(this.images[this.slideIndex]._id)
          .subscribe((result) => {
            if (this.images[this.slideIndex] && result) {
              this.imgViewSrc = `data:${
                this.images ? this.images[this.slideIndex].MIME : ''
              };base64,${result?.data}`;
              this.currentTxt = this.images[this.slideIndex].Original;
            } else {
              console.log(
                `setDialogImgSrc() => NO result for Image[${this.slideIndex}]`,
              );
            }
          });
      }
    }
  }

  getThumbImage(image: BaseImage) {
    const str64 = String.fromCharCode.apply(null, image.Thumb.data);
    return `data:${image.MIME};base64,${btoa(str64)}`;
  }

  index(image: BaseImage): number {
    if (this.images) return this.images?.indexOf(image);
    else return -1;
  }

  showSlides(n: number) {
    let i;
    let slides: HTMLCollection = document.getElementsByClassName('mySlides');
    let dots = document.getElementsByClassName('demo');
    // let dots = document.getElementsByClassName('dot');
    let captionText: HTMLElement | null = document.getElementById('caption');
    this.imgLength = slides.length;
    if (n > slides.length - 1) {
      this.slideIndex = 0;
    }
    if (n < 0) {
      this.slideIndex = this.imgLength - 1;
    }
    // this.setDialogImgSrc();
    for (i = 0; i < slides.length; i++) {
      (slides[i] as HTMLElement).style.display = 'none';
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(' active', '');
    }

    if (slides && this.slideIndex < slides.length) {
      if (slides[this.slideIndex]) {
        (slides[this.slideIndex] as HTMLElement).style.display = 'block';
        // (slides[this.slideIndex] as HTMLElement).style.opacity = '1';
      }
    }
    if (dots && this.slideIndex < dots.length) {
      if (dots[this.slideIndex]) dots[this.slideIndex].className += ' active';
    }
    if (captionText && dots && dots.length < this.slideIndex) {
      captionText.innerHTML = (dots[this.slideIndex] as any).alt;
    }
    this.setDialogImgSrc();
  }

  openChange(value: boolean) {
    this.modalOpen.emit(false);
  }

  closeDialog() {
    this.modalOpen.emit(false);
  }
}
