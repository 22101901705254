import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Cabinet } from 'src/app/models/Cabinet';
import { Item } from 'src/app/models/Item';
import { selectProfilRoles } from '../../users/+profil/profil.selectors';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { BaseImage } from 'src/app/models/Image';
import { Template } from 'src/app/models/Template';

@Component({
  selector: 'app-risks-cabinet-table',
  templateUrl: './risks-cabinet-table.component.html',
  styleUrls: ['./risks-cabinet-table.component.css'],
})
export class RisksCabinetTableComponent implements OnInit, OnChanges {
  private mainCabinet = AssessmentStoreService.currentCabinetSubject.value
    ? AssessmentStoreService.currentCabinetSubject.value
    : ({
        _id: '',
        Anlage: [{ ItemId: '', Value: '' }],
      } as unknown as Cabinet);

  @Input()
  get cabinet() {
    if (this.mainCabinet._id.length > 0) return this.mainCabinet;
    else return AssessmentStoreService.currentCabinetSubject.value;
  }
  set cabinet(cabinet: Cabinet | null) {
    if (cabinet) {
      const cab = AssessmentStoreService.currentCabinetsSubject.value.find(
        (c) => c._id === cabinet._id,
      );
      if (cab) {
        this.mainCabinet = cab;
        this.setImageArray(cab);
      }
    }
  }

  cabinet$: Observable<Cabinet | null> =
    AssessmentStoreService.currentCabinetSubject;
  template$ = AssessmentStoreService.currentTemplateSubject;
  currentPreviewImageId = undefined as string | undefined;
  currentPreviewIndex: number = 0;

  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['admin']));

  onHelper = false;
  onImages = false;
  imgPreviewSrc = undefined as string | undefined;
  imgIndex = 0;
  title = '';
  message = '';

  constructor(
    private service: AssessmentStoreService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
  ) {
    if (!AssessmentStoreService.currentTemplateSubject.value) {
      this.service.loadTemplate();
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (AssessmentStoreService.currentCabinetSubject.value && !this.cabinet) {
        this.cabinet = AssessmentStoreService.currentCabinetSubject.value;
      }
      if (
        params['id'] &&
        AssessmentStoreService.currentCabinetSubject.value?._id !== params['id']
      ) {
        this.service.loadCabinet(params['id']).subscribe((finished) => {
          this.cabinet = AssessmentStoreService.currentCabinetSubject.value;
        });
      }
    });
    if (!AssessmentStoreService.currentTemplateSubject.value) {
      this.service.loadTemplate();
    }
    if (this.cabinet$) {
      this.cabinet$?.subscribe((cabinet) => {
        if (cabinet) {
          this.setImageArray(cabinet);
        }
      });
    }
  }

  setImageArray(cabinet: Cabinet) {
    if (cabinet?.Images && cabinet?.Images.length > 0) {
      let found = false;
      cabinet?.Images.forEach((image) => {
        if (image.AsPreview) {
          this.currentPreviewImageId = image._id;
          found = true;
        }
      });
      if (!found) {
        this.currentPreviewImageId = undefined;
      } else {
        const image = this.cabinet?.Images?.find(
          (img) =>
            this.currentPreviewImageId && img._id == this.currentPreviewImageId,
        );
        if (image) {
          const str64 = String.fromCharCode.apply(null, image.Thumb.data);
          this.imgPreviewSrc = `data:${image.MIME};base64,${btoa(str64)}`;
          this.imgIndex = cabinet?.Images?.indexOf(image);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes['cabinet$']) {
        this.cabinet$?.subscribe((cabinet) => {
          if (cabinet) this.cabinet = cabinet;
        });
      }
      if (changes['cabinet']) {
        if (this.cabinet) this.setImageArray(this.cabinet);
        else {
          this.currentPreviewImageId = undefined;
          this.imgPreviewSrc = undefined;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  getImages(): BaseImage[] {
    if (this.cabinet?.Images) return this.cabinet.Images;
    else return [];
  }

  getTemplateAnlageItems(template: Template): Item[] {
    if (template.Anlage) {
      const sortItems = [] as Item[];
      template.Anlage?.map((a) => sortItems.push(a));
      return sortItems.sort((a, b) => a.ItemIndex - b.ItemIndex);
    }
    return [] as Item[];
  }

  onHelperClick(title: string, message: string | undefined) {
    this.title = title || '';
    this.message = message || '';
    this.onHelper = true;
    return false;
  }

  onBtnEditCabinetClick() {
    this.router.navigate(['/risks/editecabinet', this.cabinet?._id]);
  }

  onBtnEditTemplateClick() {
    this.router.navigate([
      '/risks/editetemplate',
      AssessmentStoreService.currentTemplateSubject.value?._id,
    ]);
  }

  onDialogClosing(open: boolean) {
    this.onImages = open; // false
  }

  ValueFromItem(item: object) {
    try {
      const result = this.cabinet?.Anlage?.find(
        (a) => a.ItemId == (item as Item)._id,
      )?.Value;
      if (result == undefined) return '';
      return result;
    } catch (err) {
      console.error(err);
      return '';
    }
  }
}
