<ng-container *ngIf="cabinet">
  Id: [{{ cabinet._id }}]
  <span style="float: right">
    <button class="btn btn-outline" (click)="onBtnEditCabinetClick()">
      Anlage bearbeiten
    </button>
    <ng-container *ngIf="isService$ | async">
      <button class="btn btn-outline" (click)="onBtnEditTemplateClick()">
        Vorlage bearbeiten
      </button>
    </ng-container>
  </span>
  <br />
  <ng-container *ngIf="template$ | async as template">
    <table class="table">
      <thead>
        <tr>
          <th *ngIf="imgPreviewSrc" class="left">Bild</th>
          <th class="left">Bezeichnung</th>
          <th class="left">Wert</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let anlageItem of getTemplateAnlageItems(template);
            let i = index
          "
        >
          <td
            *ngIf="i === 0 && imgPreviewSrc"
            [rowSpan]="template.Anlage.length"
            style="vertical-align: middle"
          >
            <div style="display: flex; flex-direction: column">
              <img
                [src]="imgPreviewSrc"
                alt="Vorschaubild"
                style="
                  align-self: center;
                  vertical-align: top;
                  align-items: center;
                  max-width: max-content;
                  max-width: max-content;
                "
              />
              <ng-container *ngIf="cabinet">
                <br />
                <a
                  [routerLink]="[]"
                  class="label clickable"
                  (click)="onImages = true"
                  style="
                    align-self: bottom;
                    position-anchor: bottom;
                    vertical-align: bottom;
                  "
                >
                  weitere Bilder
                </a>
              </ng-container>
            </div>
          </td>
          <td class="left">
            {{ anlageItem.Title }}
            <cds-icon
              *ngIf="anlageItem.Info"
              class="btn btn-sm btn-link"
              (click)="onHelperClick(anlageItem.Title, anlageItem.Info)"
              shape="info-standard"
            />
          </td>
          <td class="left">
            <div *ngIf="cabinet">{{ ValueFromItem(anlageItem) }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</ng-container>

<app-dialog-show-image
  #imgDialog
  [images]="getImages()"
  (modalOpen)="onDialogClosing($event)"
  [slideIndex]="imgIndex"
  *ngIf="onImages"
>
</app-dialog-show-image>

<!-- Info-Dialog -->
<clr-modal [(clrModalOpen)]="onHelper">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body" [innerHTML]="message"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onHelper = false">
      Ok
    </button>
  </div>
</clr-modal>
