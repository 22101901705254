import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Cabinet, compareCabinets } from 'src/app/models/Cabinet';
import { Evaluation } from 'src/app/models/Evaluation';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { Observable, Subject } from 'rxjs';
import { selectProfilRoles } from '../../users/+profil/profil.selectors';

@Component({
  selector: 'app-risks-evaluations-overview',
  templateUrl: './risks-evaluations-overview.component.html',
  styleUrls: ['./risks-evaluations-overview.component.css'],
})
export class RiskEvaluationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() cabinet: Cabinet | null =
    AssessmentStoreService.currentCabinetSubject.value;
  evaluations$: Observable<Evaluation[]> =
    AssessmentStoreService.currentEvaluationsSubject;
  evaluation$: Observable<Evaluation | null> | null = null;
  template$ = AssessmentStoreService.currentTemplateSubject;
  newEvluation$: Subject<Evaluation | null> | null = null;
  selected: Evaluation | undefined = undefined;
  extended = false;
  pdfShown = false;
  onHelper = false;
  onDelete = false;
  onNewEvaluation = false;
  pdfBase64Src = null as any;
  title = '';
  message = '';
  infoTxt = '';
  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['admin']));
  isService = false;
  isManager = false;
  online = this.service.isOnline$;

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnDestroy(): void {
    if (this.newEvluation$) {
      this.newEvluation$.unsubscribe();
      this.newEvluation$ = null;
    }
  }

  ngOnInit() {
    this.isManager$.subscribe((m) => (this.isManager = m));
  }

  getInfo(): string {
    if (this.selected?.PDFResult?.requested) {
      return 'Bearbeitung angefordert';
    }
    return '';
  }

  editEvaluationClick() {
    const id = this.selected?._id;
    if (id) {
      this.service.loadEvaluation(id); // set current Evaluation
      this.router.navigate(['/risks/editevaluation', id], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  deleteEvaluationClick() {
    const id = this.selected?._id;
    if (id && this.cabinet) {
      console.log(`Eintrag [${id}] wird gelöscht!`);
      this.evaluations$ = this.service.deleteEvaluation(this.cabinet._id, id);
      this.onDelete = false;
    }
  }

  newEvaluationClick() {
    if (this.cabinet?._id?.startsWith('offline')) return;
    if (this.cabinet && !this.evaluation$) {
      this.evaluation$ = this.service.loadNewEvaluationFor(this.cabinet._id);
      this.evaluation$.subscribe((e) => {
        if (!e) {
          console.error(
            'Evaluation wurde nicht erstellt! - currentEvaluationSubject:',
            AssessmentStoreService.currentEvaluationSubject.value,
          );
          return;
        }
        this.router.navigate(['/risks/editevaluation', e._id], {
          relativeTo: this.activatedRoute,
        });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cabinet']) {
      if (
        !compareCabinets(
          changes['cabinet'].previousValue,
          changes['cabinet'].currentValue,
        )
      ) {
        if (this.cabinet?._id) {
          this.service.loadEvaluationsByCabinet(this.cabinet._id);
        }
      }
    }
  }

  isOfflineCabinet(): boolean {
    if (this.cabinet?._id?.startsWith('offline') && !this.online.value)
      return true;
    return false;
  }

  forEvaluationDisabled(): boolean {
    if (this.selected) {
      return this.selected.PDFResult?.File != undefined;
    }
    return true;
  }

  onNewPdfResultClick() {
    // this.pdfShown = false;
    // this.extended = true;
    if (this.selected) {
      this.service.loadNewPdfFor(this.selected._id).subscribe((result) => {
        if (result) window.open(URL.createObjectURL(result), '_blank');
        // window.location.href = URL.createObjectURL(result);
      });
    }
  }

  showPdf(evaluation: Evaluation) {
    this.service.getPdfEvaluation(evaluation._id).subscribe(
      (result: Blob) => {
        if (result) window.open(URL.createObjectURL(result), '_blank');
        // window.location.href = URL.createObjectURL(result);
      },
      (error) => console.error(error),
      () => {},
    );
  }
}
