import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

import { selectProfil } from '../../users/+profil/profil.selectors';
import { ProfilActions } from '../../users/+profil/profil.actions';

import { selectCombinedFactories } from '../../factories/+store/factory.selectors';
import { FactoryActions } from '../../factories/+store/factory.actions';

import { Factory } from 'src/app/models/Factory';

import {
  selectUsers,
  selectUsersError,
  selectUsersLoading,
} from '../../users/+store/user.selectors';
import { UserActions } from '../../users/+store/user.actions';
import { AssesmentSocketService } from 'src/app/services/assesmentsocket.service';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { Subject } from 'rxjs';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-risks-location-management',
  templateUrl: './risks-location-management.component.html',
  styleUrls: ['./risks-location-management.component.css'],
})
export class UsersRightsLocationsComponent implements OnInit {
  profil$ = this.store.select(selectProfil);

  users$ = this.store.select(selectUsers);
  loading$ = this.store.select(selectUsersLoading);
  error$ = this.store.select(selectUsersError);

  // combinedLocations$: Subject<string[]> =
  //   AssessmentStoreService.currentUserLocations;

  selected: KeyValue<string, string[]> | null = null;
  locations: string[] = AssessmentStoreService.currentUserLocations.value;
  loading = false;
  error: HttpErrorResponse | undefined = undefined;
  locationUsers: { [key: string]: string[] } = {};

  detail = false;

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
    private breadcrumbService: ClrBreadcrumbService,
  ) {}

  ngOnInit(): void {
    const breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
    const breadcrumb2: ClrBreadcrumbModel = { label: 'Benutzerverwaltung' };
    this.breadcrumbService.updateBreadcrumb([breadcrumb1, breadcrumb2]);

    this.store.dispatch(ProfilActions.loadProfil());

    this.profil$.subscribe((profil) => {
      if (profil?._id) {
        // this.combinedLocations$ = this.service.loadLocations();
        this.store.dispatch(UserActions.loadUsers());
        this.refresh();
      }
    });
  }

  refresh() {
    this.service.loadLocations().subscribe((locations) => {
      this.locationUsers = {};
      locations.forEach((location) => {
        let sortation = this.locationUsers[location];
        if (!sortation) {
          this.locationUsers[location] = [];
          sortation = this.locationUsers[location];
        }
      });
      this.users$.subscribe((users) => {
        users.forEach((user) => {
          const usrString = `${user.Firstname} ${user.Lastname}`;
          user.Features.RisksLocations.forEach((location) => {
            let sortation = this.locationUsers[location];
            if (!sortation) {
              this.locationUsers[location] = [];
              sortation = this.locationUsers[location];
            }
            if (!this.locationUsers[location].includes(usrString)) {
              this.locationUsers[location].push(usrString);
            }
          });
        });
      });
    });
  }

  onSelectedEdit() {
    this.detail = true;
  }

  editLocation(location: KeyValue<string, string[]>) {
    try {
      this.selected = location;
      this.detail = true;
      console.log(`editLocation(${location.key})`, this.detail);
    } catch (err) {
      console.error(err);
    }
  }

  onSelectedClose(value: boolean) {
    this.detail = false;
    this.refresh();
  }
}
