import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { ClrDatagridComparatorInterface } from '@clr/angular';

import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { selectProfilRoles } from '../../users/+profil/profil.selectors';
import { Item } from 'src/app/models/Item';
import { Cabinet } from 'src/app/models/Cabinet';

class MyComparator implements ClrDatagridComparatorInterface<Cabinet> {
  constructor(private id: string) {}
  compare(a: Cabinet, b: Cabinet) {
    const itemA = a?.Anlage?.find((itm) => itm?.ItemId == this.id);
    const itemB = b?.Anlage?.find((itm) => itm?.ItemId == this.id);
    let result = 0;
    if (itemA?.Value == itemB?.Value) {
      result = 0;
    } else if (itemA?.Value && itemB?.Value && itemA?.Value < itemB?.Value) {
      result = -1;
    } else if (itemA?.Value && itemB?.Value && itemA?.Value > itemB?.Value) {
      result = 1;
    }
    return result;
  }
}

@Component({
  selector: 'app-risks-list-grid',
  templateUrl: './risks-list-grid.component.html',
  styleUrls: ['./risks-list-grid.component.css'],
})
export class RisksListGridComponent implements OnInit {
  gridLoadingState$ = true;
  dialogAddOpen = false;
  dialogImportOpen = false;
  dialogDeleteOpen = false;
  selected = [] as Cabinet[];
  sortations: { [key: string]: MyComparator } = {};
  templateObserver$ = AssessmentStoreService.currentTemplateSubject;
  cabinetsObserver$ =
    AssessmentStoreService.currentCabinetsSubject.asObservable();
  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['admin']));

  risksOnline$ = this.service.isOnline$;
  risksInSync$ = this.service.isInSync$;

  currentlyInSync = false;

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
    private router: Router,
    private breadcrumbService: ClrBreadcrumbService,
  ) {}

  ngOnInit(): void {
    const breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
    const breadcrumb2: ClrBreadcrumbModel = { label: 'Gefährdungsbeurteilung' };
    this.breadcrumbService.updateBreadcrumb([breadcrumb1, breadcrumb2]);

    this.service
      .loadCabinets()
      .subscribe(() => (this.gridLoadingState$ = false));

    if (!AssessmentStoreService.currentTemplateSubject.value)
      this.service.loadTemplate();
  }

  refresh(online: boolean, syncFinished: boolean) {
    if (!this.currentlyInSync && !syncFinished) {
      this.currentlyInSync = true;
    }

    if (online && this.currentlyInSync && syncFinished) {
      console.log('SYNC FINISHED ====> try to refresh table');
      this.currentlyInSync = false;
      this.ngOnInit();
    }
  }

  getTemplateAnlageItems(): Item[] {
    if (AssessmentStoreService.currentTemplateSubject.value) {
      const sortItems = [] as Item[];
      const template = AssessmentStoreService.currentTemplateSubject.value;
      template?.Anlage?.map((a) => sortItems.push(a));
      return sortItems.sort((a, b) => a.ItemIndex - b.ItemIndex);
    }
    return [] as Item[];
  }

  /**
   * It returns an array of rows
   * @param valueObjects as { ItemId: string; Value: object }[]
   * @returns an array of value-pairs(row) sorted by columns
   */
  getValues(
    cabinetId: string,
    valueObjects: { ItemId: string; Value: object }[],
  ) {
    const sorted = this.getTemplateAnlageItems();
    const list = [sorted.length] as any[];
    try {
      for (let i = 0; i < sorted.length; i += 1) {
        const values = AssessmentStoreService.currentCabinetsSubject.value.find(
          (c) => c._id == cabinetId,
        )?.Anlage;
        const val = values?.find((o) => o.ItemId == sorted[i]._id);
        list[i] = {
          [`col${val?.ItemId}`]: val?.Value,
          ItemId: val?.ItemId,
        };
      }
    } catch (err) {
      console.error(`ERROR (valueObjects[${valueObjects?.length}])`, err);
    }
    return list;
  }

  sortationFor(itemId: string): MyComparator {
    let sortation = this.sortations[itemId];
    if (!sortation) {
      this.sortations[itemId] = new MyComparator(itemId);
      sortation = this.sortations[itemId];
    }
    return sortation;
  }

  isLoading() {
    return of(this.gridLoadingState$);
  }

  onBtnEditClick() {
    const cabinet = this.selected[0] as Cabinet;
    this.router.navigate(['/risks/review', cabinet._id]);
  }

  onBtnEditTemplateClick() {
    this.router.navigate([
      '/risks/editetemplate',
      AssessmentStoreService.currentTemplateSubject.value?._id,
    ]);
  }

  onDialogAddClick() {
    this.dialogAddOpen = false;
  }

  onCabinetAdd(newCabinet: Cabinet | null) {
    this.dialogAddOpen = false;
  }

  onBtnAddClick() {
    this.dialogAddOpen = true;
  }

  onBtnImportClick() {
    this.dialogImportOpen = true;
  }

  onDialogImportClick() {
    // close Dialog and refresh
    this.dialogImportOpen = false;
    this.ngOnInit();
  }

  onDeleteSelected() {
    this.dialogDeleteOpen = true;
  }

  deleteSelected() {
    this.selected.map((s) => {
      this.service.deleteCabinet(s._id);
    });
    this.dialogDeleteOpen = false;
  }
}
