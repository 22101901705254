<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <h6 class="card-header">
    <cds-icon shape="factory" size="md"></cds-icon>
    Standorte
    <span style="float: right">
      <cds-icon shape="view-list" size="md"></cds-icon>
    </span>
  </h6>

  <div class="card-block">
    <h4 class="card-title" *ngIf="error?.status">
      <div class="alert alert-danger" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error?.status }} |
              {{ error?.statusText }}
              <ng-container *ngIf="error?.error.name">
                | {{ error?.error.name }} | {{ error?.error.message }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </h4>

    <div class="card-text">
      <button
        class="btn btn-outline"
        (click)="onSelectedEdit()"
        [disabled]="!selected"
      >
        <cds-icon shape="dot-circle"></cds-icon> bearbeiten
      </button>

      <clr-datagrid
        [clrDgLoading]="loading"
        [clrDgRowSelection]="true"
        [(clrDgSingleSelected)]="selected"
      >
        <clr-dg-placeholder>
          Wir konnten keine Einträge finden!
        </clr-dg-placeholder>

        <clr-dg-column [clrDgField]="'key'">
          <cds-icon shape="factory"></cds-icon>&nbsp;Standort
        </clr-dg-column>

        <clr-dg-column [clrDgField]="'value'">
          <cds-icon shape="tag"></cds-icon>&nbsp;Benutzer
        </clr-dg-column>

        <clr-dg-row
          *clrDgItems="let location of locationUsers | keyvalue"
          [clrDgItem]="location"
          [clrDgSelectable]="true"
        >
          <clr-dg-cell (click)="editLocation(location)">
            {{ location.key }}
          </clr-dg-cell>
          <clr-dg-cell (click)="editLocation(location)">
            {{ location.value.join(", ") }}
          </clr-dg-cell>
        </clr-dg-row>
        <!-- <clr-dg-footer>
          <clr-dg-pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
              >Einträge pro Seite</clr-dg-page-size
            >
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid> -->

        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
              >Einträge pro Seite</clr-dg-page-size
            >
            <!-- {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} von
          {{ pagination.totalItems }} Anlagen -->
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>
  <!-- <div class="card-footer">Footer</div> -->
</div>

<app-risks-location-management-form
  *ngIf="detail"
  [location]="selected?.key"
  (done)="onSelectedClose($event)"
>
</app-risks-location-management-form>
