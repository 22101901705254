import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { ClrLoadingState } from '@clr/angular';
import { Cabinet } from 'src/app/models/Cabinet';
import { RiskTabCabinetComponent } from '../risks-tab-cabinet/risks-tab-cabinet.component';
import { RiskTabCabinetImagesComponent } from '../risks-tab-images-cabinet/risks-tab-images-cabinet.component';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

@Component({
  selector: 'app-risks-cabinet-edition',
  templateUrl: './risks-cabinet-edition.component.html',
  styleUrls: ['./risks-cabinet-edition.component.css'],
})
export class RisksEditCabinetComponent implements OnInit, OnDestroy {
  loading$ = new Observable<boolean>();
  error$ = new Observable<HttpErrorResponse | null>();
  cabinet: Cabinet | null = AssessmentStoreService.currentCabinetSubject.value;

  @ViewChild('anlageTab') tabAnlage = {} as RiskTabCabinetComponent;
  @ViewChild('imgTab') tabFotos = {} as RiskTabCabinetImagesComponent;

  submitAnlageState: ClrLoadingState = ClrLoadingState.DEFAULT;
  submitPruefungState: ClrLoadingState = ClrLoadingState.DEFAULT;
  submitGefaehrdungenState: ClrLoadingState = ClrLoadingState.DEFAULT;

  helper = false;
  text = '';

  constructor(
    private service: AssessmentStoreService,
    private route: ActivatedRoute,
    private breadcrumbService: ClrBreadcrumbService,
  ) {}

  ngOnInit(): void {
    const breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
    const breadcrumb2: ClrBreadcrumbModel = {
      label: 'Gefährdungsbeurteilung',
      url: '/risks/overview',
    };
    const breadcrumb3: ClrBreadcrumbModel = {
      label: 'Anlage',
      url: `/risks/review/${this.cabinet?._id}`,
    };
    const breadcrumb4: ClrBreadcrumbModel = { label: 'Bearbeitung' };
    this.breadcrumbService.updateBreadcrumb([
      breadcrumb1,
      breadcrumb2,
      breadcrumb3,
      breadcrumb4,
    ]);
    this.route.params.subscribe((params) => {
      const id = params['id'] as string;
      if (AssessmentStoreService.currentCabinetSubject.value?._id !== id) {
        this.service.loadCabinet(id).subscribe((finished) => {
          if (finished) {
            const breadcrumb3new: ClrBreadcrumbModel = {
              label: 'Anlage',
              url: `/risks/review/${AssessmentStoreService.currentCabinetSubject.value?._id}`,
            };
            this.breadcrumbService.updateBreadcrumb([
              breadcrumb1,
              breadcrumb2,
              breadcrumb3new,
              breadcrumb4,
            ]);
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.uploadSub$.unsubscribe();
  }

  file: File | null = null;
  excelFile: File | null = null;
  uploadProgress = 0;
  uploadSub$ = new Subscription();

  setCabinet(event: any) {
    this.cabinet = event as Cabinet;
  }

  refresh(event: Cabinet) {
    this.cabinet = event;
    if (this.cabinet) {
      if (this.tabAnlage && this.cabinet) {
        this.tabAnlage.cabinet = this.cabinet;
      }
      if (this.tabFotos && this.cabinet) {
        this.tabFotos.cabinet = this.cabinet;
      }
    }
  }

  initAnlage($event: any) {
    try {
      this.tabAnlage = $event as RiskTabCabinetComponent;
      this.tabAnlage.cabinet = this.cabinet; // new
    } catch (err) {
      console.error(err);
    }
  }

  initImages($event: any) {
    try {
      this.tabFotos = $event as RiskTabCabinetImagesComponent;
      this.tabFotos.cabinet = this.cabinet; // new
    } catch (err) {
      console.error(err);
    }
  }

  imgTabDisabled(): boolean {
    const disabled = this.cabinet?._id.startsWith('offline') ? true : false;
    return disabled;
  }
}
