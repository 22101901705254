import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cabinet } from 'src/app/models/Cabinet';
import { RiskTabResultsComponent } from '../risks-tab-results/risks-tab-results.component';
import { EvaluationCalculations } from 'src/app/models/Evaluation';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

@Component({
  selector: 'app-risks-review',
  templateUrl: './risks-review.component.html',
  styleUrls: ['./risks-review.component.css'],
})
export class RisksReviewComponent implements OnInit {
  cabinet$: Observable<Cabinet | null> =
    AssessmentStoreService.currentCabinetSubject;
  evaluationCalculations$ = {} as EvaluationCalculations;
  template$ = AssessmentStoreService.currentTemplateSubject; // new Observable<Template>();
  loading$ = new Observable<boolean>();
  error$ = new Observable<HttpErrorResponse | null>();
  evaluations$ = AssessmentStoreService.currentEvaluationsSubject; // new Observable<Evaluation[]>();
  evaluation$ = AssessmentStoreService.currentEvaluationSubject; // undefined as unknown as Observable<Evaluation>;

  evaluation = AssessmentStoreService.currentEvaluationSubject.value; // undefined as unknown as Evaluation;

  @ViewChild('Auswertung') tabAuswertung = {} as RiskTabResultsComponent;

  constructor(
    private service: AssessmentStoreService,
    private route: ActivatedRoute,
    private breadcrumbService: ClrBreadcrumbService,
  ) {}

  ngOnInit(): void {
    const breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
    const breadcrumb2: ClrBreadcrumbModel = {
      label: 'Gefährdungsbeurteilung',
      url: '/risks/overview',
    };
    const breadcrumb3: ClrBreadcrumbModel = { label: 'Anlage' };
    this.breadcrumbService.updateBreadcrumb([
      breadcrumb1,
      breadcrumb2,
      breadcrumb3,
    ]);

    this.route.params.subscribe((params) => {
      if (
        params['id'] &&
        AssessmentStoreService.currentCabinetSubject.value?._id !== params['id']
      ) {
        this.service.loadCabinet(params['id']);
      }
    });
  }

  initAuswertung($event: any) {
    try {
      this.tabAuswertung = $event as RiskTabResultsComponent;
      if (this.evaluation) {
        this.tabAuswertung.setEvaluation(this.evaluation);
      }
    } catch (err) {
      console.error(err);
    }
  }
}
