import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from 'src/app/models/User';

import { UserActions } from '../../users/+store/user.actions';

import {
  selectUsers,
  selectUsersError,
  selectUsersLoading,
} from '../../users/+store/user.selectors';

@Component({
  selector: 'app-risks-location-management-form',
  templateUrl: './risks-location-management-form.component.html',
  styleUrls: ['./risks-location-management-form.component.css'],
})
export class UsersRightsLocationsFormComponent implements OnInit {
  @Input() location: string | undefined = undefined;
  // @Input() locationUsers: { [key: string]: string[] } = {};
  @Output() done = new EventEmitter<boolean>();

  users$ = this.store.select(selectUsers);
  loading$ = this.store.select(selectUsersLoading);
  error$ = this.store.select(selectUsersError);

  storage: {
    IsEnabled: boolean;
    User: User;
  }[] = [];

  selected: {
    IsEnabled: boolean;
    User: User;
  }[] = [];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.users$.subscribe((users) => {
      this.storage = [];

      users.forEach((user) => {
        const isEnabled = user.Features.RisksLocations.some(
          (c) => c === this.location,
        );

        this.storage.push({
          IsEnabled: isEnabled,
          User: user,
        });
      });
    });
  }

  onSubmit() {
    console.log('onSubmit()');
    this.storage.forEach((s) => {
      const t = this.selected.some(
        (f) =>
          s.User._id.trim().toLocaleLowerCase() ===
          f.User._id.trim().toLocaleLowerCase(),
      );
      if (this.location) {
        if (t) {
          console.log(
            `onSubmit() Push SelectedUsr[${s.User._id}] to Location[${this.location}]`,
          );

          this.store.dispatch(
            UserActions.patchPushRisksUserLocations({
              user: s.User._id,
              location: this.location,
            }),
          );
        } else {
          console.log(
            `onSubmit() remove SelectedUsr[${s.User._id}] from Location[${this.location}]`,
          );
          this.store.dispatch(
            UserActions.patchPullRisksUserLocations({
              user: s.User._id,
              location: this.location,
            }),
          );
        }
      }
    });

    this.done.emit(true);
  }

  onCancel() {
    this.done.emit(false);
  }
}
