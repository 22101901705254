import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Cabinet } from 'src/app/models/Cabinet';
import { CabinetImg } from 'src/app/models/Image';
import { ClrLoadingState } from '@clr/angular';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import {
  DialogAddImageComponent,
  CheckObj,
} from '../dialog-add-image/dialog-add-image.component';
import { BaseImage } from 'src/app/models/Image';

@Component({
  selector: 'app-risks-tab-images-cabinet',
  templateUrl: './risks-tab-images-cabinet.component.html',
  styleUrls: ['./risks-tab-images-cabinet.component.css'],
})
export class RiskTabCabinetImagesComponent implements OnInit, OnChanges {
  @Input() cabinet: Cabinet | null = null;
  @Input() disabled = false;
  @Input() maxImageCount = 3;
  @Output() updated = new EventEmitter();
  @Output() init = new EventEmitter();
  filesDialog: DialogAddImageComponent | null = null;
  online = this.service.isOnline$;

  infoTxt = '';
  addBtnDisabled = false;
  dialogAddFile = false;
  title = '';
  message = '';
  imgViewSrc = '';
  dialogViewImg = false;
  imgIndex = 0;

  submitTabState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(private service: AssessmentStoreService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cabinet']) {
      this.isMaxImageCount();
      console.log('changes[cabinet] images:', this.cabinet?.Images?.length);
    }
  }

  ngOnInit() {
    this.init.emit(this);
  }

  setDialog($event: any) {
    this.filesDialog = $event as DialogAddImageComponent;
    this.isMaxImageCount();
  }

  getDialogImgCount(): number {
    if (this.cabinet?.Images) {
      let count = this.maxImageCount - this.cabinet.Images.length;
      count = count < 0 ? 0 : count;
      return count;
    }
    return this.maxImageCount;
  }

  isMaxImageCount(): boolean {
    if (
      this.cabinet?.Images &&
      this.cabinet?.Images?.length < this.maxImageCount
    ) {
      this.addBtnDisabled = false;
      this.infoTxt = '';
    } else {
      this.addBtnDisabled = true;
      this.infoTxt = `\tMaximal ${this.maxImageCount} Bilder möglich.`;
      return true;
    }
    return false;
  }

  onDialogAddFileClosing() {
    this.dialogAddFile = false;
  }

  onAddFileClick() {
    if (this.disabled) return false;
    this.dialogAddFile = !this.isMaxImageCount();
    return false;
  }

  getThumbImage(image: CabinetImg) {
    const str64 = String.fromCharCode.apply(null, image.Thumb.data);
    return `data:${image.MIME};base64,${btoa(str64)}`;
  }

  showImage(image: CabinetImg) {
    const idx = this.cabinet?.Images?.indexOf(image);
    this.imgIndex = idx ? idx : 0;
    this.dialogViewImg = true;
  }

  onDialogClosing(open: boolean) {
    console.log('DIALOG WILL BE CLOSED! value from dialog:', open);
    this.dialogViewImg = false;
  }

  getImages(): BaseImage[] {
    return this.cabinet?.Images ? this.cabinet.Images : [];
  }

  onSetPreviewImg(image: CabinetImg) {
    this.submitTabState = ClrLoadingState.LOADING;
    if (this.cabinet) {
      const images = [] as CabinetImg[];
      this.cabinet?.Images?.map((img) => {
        if (img._id == image._id) {
          img.AsPreview = true;
        } else {
          img.AsPreview = false;
        }
        images.push(img);
      });
      const patchCabinet = {
        _id: this.cabinet._id,
        Uid: this.cabinet.Uid,
        Anlage: this.cabinet.Anlage,
        Images: images,
      } as Cabinet;
      this.service.patchCabinet(patchCabinet).subscribe((cabinet) => {
        this.cabinet = cabinet;
        this.submitTabState = ClrLoadingState.SUCCESS;
      });
    } else {
      this.submitTabState = ClrLoadingState.SUCCESS;
    }
  }

  uploadFile(files: CheckObj[], index: number) {
    console.log(`updateFile() file[${index}] of `, files.length);
    if (index == files.length) {
      console.log('onUpdateFile()', 'ENDE');
      return;
    }
    if (index >= 0 && index < files.length) {
      const fileData: CheckObj = files[index];
      console.log('onUpdateFile()', fileData);
      if (fileData.file && this.cabinet) {
        this.service
          .postCabinetImage(fileData, this.cabinet)
          .subscribe((result) => {
            console.log('updateFile() => got result', result);
            this.cabinet = result as Cabinet;
            // this.updated.emit(this.cabinet);
            this.filesDialog?.fileSend(true, fileData.idx);
            index = index + 1;
            this.uploadFile(files, index);
          });
      }
    } else {
      console.log('onUpdateFile()', 'ENDE');
      // this.updated.emit(this.cabinet);
      return;
    }
  }

  onUpdateFiles(fileData: any[]) {
    console.log('onUpdateFile()', fileData);
    this.uploadFile(fileData, 0);
  }

  onDeleteFile(control: any) {
    this.submitTabState = ClrLoadingState.LOADING;
    if (this.cabinet) {
      console.log(`onDeleteFile control?._id : ${control?._id}`);
      this.service
        .deleteCabinetImage(this.cabinet._id, control?._id)
        .subscribe((C) => {
          this.cabinet = C as Cabinet;
          this.updated.emit(C as Cabinet);
          this.submitTabState = ClrLoadingState.SUCCESS;
        });
    } else {
      this.submitTabState = ClrLoadingState.SUCCESS;
    }
  }
}
