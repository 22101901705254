<ng-container>
  {{ getInfo() }}
  <span style="float: right">
    <button
      class="btn btn-outline"
      (click)="editEvaluationClick()"
      [disabled]="forEvaluationDisabled()"
    >
      <cds-icon shape="pdf-file" />
      Bearbeiten
    </button>
    <button
      *ngIf="(isManager$ | async) || (isService$ | async)"
      class="btn btn-outline"
      (click)="onDelete = true"
      [disabled]="forEvaluationDisabled()"
    >
      <cds-icon shape="pdf-file" />
      Löschen
    </button>
    <button
      *ngIf="online && isService$ | async"
      class="btn btn-outline"
      (click)="onNewPdfResultClick()"
      [disabled]="forEvaluationDisabled()"
    >
      <cds-icon shape="pdf-file" />
      PDF erstellen
    </button>
    <button
      [clrLoading]="onNewEvaluation"
      class="btn btn-outline"
      (click)="newEvaluationClick()"
      [disabled]="isOfflineCabinet()"
    >
      Neue Bewertung
    </button>
  </span>
  <br />

  <ng-container *ngIf="evaluations$ | async as evaluations">
    <!-- ------------------------------- -->
    <clr-datagrid class="table" #anlagegrid [(clrDgSingleSelected)]="selected">
      <clr-dg-placeholder
        >Wir konnten keine Einträge finden!</clr-dg-placeholder
      >
      <ng-container *ngIf="evaluations && evaluations.length > 0">
        <!-- ----------column-------------- -->
        <clr-dg-column [clrDgField]="'Name'"> Name </clr-dg-column>
        <clr-dg-column [clrDgField]="'PDFResult.File'"> PDF </clr-dg-column>
        <clr-dg-column [clrDgField]="'createdAt'"> Erstellt </clr-dg-column>
        <clr-dg-column [clrDgField]="'updatedAt'"> Geändert </clr-dg-column>
        <!-- <clr-dg-column [clrDgField]="'PDFResult'"> Bewertet </clr-dg-column> -->
        <!-- -------------row---------------- -->
        <clr-dg-row *clrDgItems="let item of evaluations" [clrDgItem]="item">
          <clr-dg-cell>{{ item.Name }} </clr-dg-cell>
          <clr-dg-cell>
            <ng-container
              *ngIf="
                item.PDFResult?.File !== undefined &&
                item.PDFResult?.File !== null
              "
            >
              <cds-icon shape="pdf-file" />
              <a [routerLink]="[]" (click)="showPdf(item)" role="button">
                {{ item.PDFResult.File }}
              </a>
            </ng-container>
          </clr-dg-cell>
          <clr-dg-cell
            >{{ item.createdAt | date: "yyyy-MM-dd\nHH:mm:ss" }}
          </clr-dg-cell>
          <clr-dg-cell>{{
            item.updatedAt | date: "yyyy-MM-dd\nHH:mm:ss"
          }}</clr-dg-cell>
          <!-- <clr-dg-cell style="align-items: start; display: flex">
                <clr-checkbox-container
                  style="align-items: start; display: flex"
                >
                  <clr-checkbox-wrapper
                    style="align-items: start; display: flex"
                  >
                    <input
                      style="align-items: start; margin-top: 0px; display: flex"
                      type="checkbox"
                      clrCheckbox
                      [value]="
                        item.PDFResult?.File !== undefined &&
                        item.PDFResult?.File !== null
                      "
                      [checked]="
                        item.PDFResult?.File !== undefined &&
                        item.PDFResult?.File !== null
                      "
                      disabled="true"
                      name="Bewertung erfolgt"
                      id="cb{{ item._id }}"
                    />
                    <label for="cb{{ item._id }}">Bewertung erfolgt</label>
                  </clr-checkbox-wrapper>
                </clr-checkbox-container>
              </clr-dg-cell> -->
        </clr-dg-row>
        <!-- ------------detail-(Anlage)----- -->
      </ng-container>
    </clr-datagrid>
    <!-- ------------------------------- -->
  </ng-container>
</ng-container>

<!-- popup-windows -->

<clr-modal [(clrModalOpen)]="onDelete">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body" [innerHTML]="'Eintrag endgültig löschen?'">
    <!-- <app-risks-evaluations
            #auswertungenTab
            [cabinet$]="cabinet$"
            (updated)="refresh($event)"
            (init)="initAuswertung($event)"
            (changed)="calulationFor($event)"
          ></app-risks-evaluations> -->
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="deleteEvaluationClick()"
    >
      Ok
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="onNewEvaluation">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body" [innerHTML]="message">
    <!-- <app-risks-evaluations
            #auswertungenTab
            [cabinet$]="cabinet$"
            (updated)="refresh($event)"
            (init)="initAuswertung($event)"
            (changed)="calulationFor($event)"
          ></app-risks-evaluations> -->
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="onNewEvaluation = false"
    >
      Ok
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="onHelper">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body" [innerHTML]="message"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onHelper = false">
      Ok
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="extended" clrModalSize="lg">
  <h3 class="modal-title">
    <cds-icon shape="pdf-file" size="md"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body">
    <!-- [useBrowserLocale]="true" -->
    <ngx-extended-pdf-viewer
      *ngIf="pdfShown; else elsetest"
      [base64Src]="pdfBase64Src"
      [height]="'60vh'"
      [showBorders]="true"
      [showHandToolButton]="true"
      [handTool]="true"
      [filenameForDownload]="'FILENAME.pdf'"
    >
    </ngx-extended-pdf-viewer>
    <ng-template #elsetest>
      <div class="pdf-viewer-template">
        <span class="spinner spinner-inline"></span>
        <!-- <span *ngIf="document?.Filesize">
          {{ document.Filesize | filesize }}
          | {{ document.Filename }}
        </span> -->
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="extended = false">
      Ok
    </button>
  </div>
</clr-modal>
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
