import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Cabinet } from 'src/app/models/Cabinet';
import { compareEvaluations, Evaluation } from 'src/app/models/Evaluation';
import { BaseImage } from 'src/app/models/Image';
import { selectProfilRoles } from '../../users/+profil/profil.selectors';
import { RiskTabEvaluationComponent } from '../risks-tab-evaluation/risks-tab-evaluation.component';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { RiskTabEvaluationImagesComponent } from '../risks-tab-images-evaluation/risks-tab-images-evaluation.component';

import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

@Component({
  selector: 'app-risks-evaluation-edition',
  templateUrl: './risks-evaluation-edition.component.html',
  styleUrls: ['./risks-evaluation-edition.component.css'],
})
export class RisksEditEvaluationComponent implements OnInit, OnDestroy {
  @ViewChild('pruefungTab') tabPruefung = {} as RiskTabEvaluationComponent;
  @ViewChild('gefaehrdungTab') tabGefaehrdung =
    {} as RiskTabEvaluationComponent;
  @ViewChild('fotosTab') tabFotos: RiskTabEvaluationImagesComponent | null =
    null;
  evaluation$: BehaviorSubject<Evaluation | null> =
    AssessmentStoreService.currentEvaluationSubject;
  cabinet$: BehaviorSubject<Cabinet | null> =
    AssessmentStoreService.currentCabinetSubject;
  evaluation: Evaluation | null =
    AssessmentStoreService.currentEvaluationSubject.value;
  onHelper = false;
  onNewEvaluation = false;
  images = false;
  imageArray = [] as BaseImage[];
  text = '';
  imgViewSrc = undefined as string | undefined;
  imgIndex = 0;
  title = '';
  message = '';
  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['admin']));
  online = this.service.isOnline$;

  formBuilder = inject(FormBuilder);

  formEvaName = this.formBuilder.group({
    evaluationName: new FormControl(this.evaluation?.Name, {
      nonNullable: false,
    }),
  });

  formMobile = this.formBuilder.group({
    Image: this.formBuilder.group({
      Path: new FormControl('', { nonNullable: true }),
      Name: new FormControl('', { nonNullable: true }),
      id: new FormControl('', { nonNullable: true }),
      cid: new FormControl('', { nonNullable: true }),
    }),
    Images: this.formBuilder.array([]),
  });

  breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
  breadcrumb2: ClrBreadcrumbModel = {
    label: 'Gefährdungsbeurteilung',
    url: '/risks/overview',
  };
  breadcrumb3: ClrBreadcrumbModel = {
    label: 'Anlage',
    url: `/risks/review/${this.cabinet$.value?._id}`,
  };
  breadcrumb4: ClrBreadcrumbModel = { label: 'Bearbeitung' };

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: ClrBreadcrumbService,
  ) {
    this.evaluation$?.subscribe((evaluation) => {
      if (evaluation) {
        this.imageArray = [];
        evaluation?.Images?.forEach((img) => this.imageArray.push(img));
      }
      if (
        (!this.evaluation && evaluation) ||
        (this.evaluation &&
          evaluation &&
          !compareEvaluations(this.evaluation, evaluation))
      ) {
        console.log('ngOnChanges(evaluation$) got evaluation:', evaluation);
        this.evaluation = evaluation;
        if (this.tabFotos) this.tabFotos.evaluation = this.evaluation;
        this.imageArray = [];
        evaluation?.Images?.forEach((img) => this.imageArray.push(img));
        this.service
          .loadCabinet(evaluation.Cabinet._id)
          .subscribe((finished) => {
            // if(finished) this.cabinet$ = AssessmentStoreService.currentCabinetSubject;
          });
        evaluation?.Images?.forEach((img) => this.imageArray.push(img));
        const form = this.formEvaName.get('evaluationName');
        form?.patchValue(evaluation.Name);
        this.breadcrumb3 = {
          label: 'Anlage',
          url: `/risks/review/${evaluation.Cabinet?._id}`,
        };
      } else {
        if (evaluation) this.evaluation = evaluation;
        const form = this.formEvaName.get('evaluationName');
        form?.patchValue(this.evaluation?.Name);
        this.breadcrumb3 = {
          label: 'Anlage',
          url: `/risks/review/${this.cabinet$.value?._id}`,
        };
      }
      this.breadcrumbService.updateBreadcrumb([
        this.breadcrumb1,
        this.breadcrumb2,
        this.breadcrumb3,
        this.breadcrumb4,
      ]);
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.updateBreadcrumb([
      this.breadcrumb1,
      this.breadcrumb2,
      this.breadcrumb3,
      this.breadcrumb4,
    ]);

    this.route.params.subscribe((params) => {
      if (
        params['id'] !==
        AssessmentStoreService.currentEvaluationSubject.value?._id
      ) {
        this.service.loadEvaluation(params['id']);
      }
    });
  }

  imgForwards() {
    if (this.evaluation?.Images) {
      if (this.imgIndex < this.evaluation.Images.length - 1) {
        this.imgIndex += 1;
      } else {
        this.imgIndex = 0;
      }
      this.setDialogImgSrc();
    }
  }

  imgBackwards() {
    if (this.evaluation?.Images) {
      if (this.imgIndex > 0) {
        this.imgIndex -= 1;
      } else {
        this.imgIndex = this.evaluation.Images.length - 1;
      }
      this.setDialogImgSrc();
    }
  }

  setDialogImgSrc() {
    if (this.evaluation?.Images) {
      this.service
        .loadImage(this.evaluation.Images[this.imgIndex]._id)
        .subscribe((result) => {
          this.imgViewSrc = `data:${
            this.evaluation?.Images
              ? this.evaluation.Images[this.imgIndex].MIME
              : ''
          };base64,${result?.data}`;
        });
    }
  }

  ngOnDestroy(): void {
    this.uploadSub$.unsubscribe();
  }

  file: File | null = null;
  excelFile: File | null = null;
  uploadProgress = 0;
  uploadSub$ = new Subscription();

  evaluationUpdate(event: any) {
    if (event) {
      this.imageArray = [];
      event?.Images?.forEach((img: BaseImage) => this.imageArray.push(img));
    }
    if (
      (this.evaluation && !compareEvaluations(event, this.evaluation)) ||
      (!this.evaluation && event)
    ) {
      console.log('evaluationUpdate() evaluation changed:', event);
      this.service.loadEvaluation(event._id).subscribe((finished) => {
        if (finished) {
          this.evaluation =
            AssessmentStoreService.currentEvaluationSubject.value;
        }
      });
    }
  }

  refresh(newEvaluation: any) {
    if (newEvaluation) {
      this.evaluation = newEvaluation;
    }
  }

  initPruefung($event: any) {
    try {
      this.tabPruefung = $event as RiskTabEvaluationComponent;
    } catch (err) {
      console.error(err);
    }
  }

  initGefaehrdung($event: any) {
    try {
      this.tabGefaehrdung = $event as RiskTabEvaluationComponent;
    } catch (err) {
      console.error(err);
    }
  }

  initFiles($event: any) {
    try {
      this.tabFotos = $event as RiskTabEvaluationImagesComponent;
      if (this.tabFotos) this.tabFotos.evaluation = this.evaluation;
    } catch (err) {
      console.error(err);
    }
  }

  initAuswertung($event: any) {
    // try {
    //   this.tabAuswertung = $event as RiskTabResultsComponent;
    //   if (this.evaluation) {
    //     this.tabAuswertung.setEvaluation(this.evaluation);
    //   }
    // } catch (err) {
    //   console.error(err);
    // }
  }

  evaluationFinishedClick() {
    if (this.evaluation) {
      this.evaluation.PDFResult.requested = true;
      this.service.patchEvaluation(this.evaluation).subscribe((evaluation) => {
        if (evaluation) {
          this.evaluation = evaluation;
          // this.cabinet = evaluation.Cabinet;
          this.router.navigate(['/risks/review', this.cabinet$.value?._id], {
            relativeTo: this.route,
          });
        } else {
          console.warn('onSubmitEvaName() evaluation:', evaluation);
        }
      });
    }
  }

  onSubmitEvaName(event: any) {
    const formEvaName = this.formEvaName.getRawValue();
    const name = formEvaName.evaluationName;
    if (this.evaluation && name) {
      this.evaluation.Name = name;
      this.service.patchEvaluation(this.evaluation).subscribe((evaluation) => {
        if (evaluation) {
          this.evaluation = evaluation;
          // this.cabinet = evaluation.Cabinet;
          this.formEvaName.get('evaluationName')?.setValue(evaluation.Name);
        } else {
          console.warn('onSubmitEvaName() evaluation:', evaluation);
        }
      });
    }
  }

  formDisabled(): boolean {
    const disabled =
      this.evaluation?.PDFResult?.requested !== undefined &&
      this.evaluation.PDFResult.requested;
    return disabled;
  }

  imgTabDisabled(): boolean {
    return this.evaluation?._id?.startsWith('offline') ? true : false;
  }

  hasResult(): boolean {
    return this.evaluation?.PDFResult?.File != undefined;
  }
}
